const useAnalytics = () => {
  const { data } = useBootstrap();
  const gtm = useGtm();

  const addOrRemoveFromCart = (product: any, event: string) => {
    if (!gtm || !process.client) {
      return;
    }

    gtm.trackEvent({
      event,
      ecommerce: {
        currency: data.value?.currencies?.current_currency?.iso_code,
        value: product.discountPrice,
        items: [
          {
            item_id: product.id_product,
            item_name: product.name,
            index: 0,
            item_brand: product.brand,
            item_category: product.category_name,
            price: product.price_amount,
            quantity: product.qty,
          },
        ],
      },
    });
  };

  const makePurchase = (order: any) => {
    if (!gtm || !process.client) {
      return;
    }

    const items = Object.values(order.products).map((item: any, index: number) => ({
      item_id: item.id_product,
      item_name: item.name,
      index: index,
      price: item.product_price_wt,
      quantity: item.quantity,
    }));

    gtm.trackEvent({
      event: 'purchase',
      ecommerce: {
        transactionId: order.details.id,
        value: order.totals.total.amount,
        tax: order.subtotals.tax.amount,
        shipping: order.subtotals.shipping.amount,
        currency: data.value?.currencies?.current_currency?.iso_code,
        items,
      },
    });
  };

  return {
    addOrRemoveFromCart,
    makePurchase,
  };
};

export default useAnalytics;
